import { useHooks } from "@/composables/useHooks";

export function useDialogHooks() {
    const { addHook, invokeHook } = useHooks();

    const onBeforeOpen = (hook: () => any) => addHook('before', hook)
    const onAfterOpen = (hook: () => any) => addHook('after', hook)

    const invokeBeforeOpen = (e: any) => invokeHook('before')
    const invokeAfterOpen = () => invokeHook('after')

    return {
        onBeforeOpen,
        onAfterOpen,
        invokeBeforeOpen,
        invokeAfterOpen
    }
}
import { useDialogHooks } from "./useDialogHooks";

const dialogPull = [] as any[];
const activeDialog = computed(() => dialogPull.slice(-1)[0]);

function addDialogToPull(dialog: any) {
    dialogPull.push({ dialogState: dialog });
}

function removeDialogFromPull(dialog: any) {
    const index = dialogPull.findIndex(d => d.dialogState == dialog)
    if (index >= 0) {
        dialogPull.splice(index, 1);
    }
}

function processOpenHooks(hooks: any) {
    //invoke hook before
    hooks.invokeBeforeOpen();
    //invoke hook after
    setTimeout(() => hooks.invokeAfterOpen(), 300)
}

function escHandler(e: any) {
    const dialogState = dialogPull.slice(-1)[0];

    if (!dialogState || e.key !== "Escape") return;

    //close active dialog
    dialogState.dialogState.value = false;
}

function addEventListeners() {
    if(process.client)
    document.addEventListener('keydown', escHandler);
}

export function useDialog(dialog: any) {
    const hooks = useDialogHooks();

    watchEffect(() => {
        if (dialog.value) {
            processOpenHooks(hooks);
            addDialogToPull(dialog);
        }
        else {
            removeDialogFromPull(dialog);
        }
    })

    return {
        ...hooks,
        dialog,
    }
}

export {
    activeDialog
}


addEventListeners();
